import React from "react";
import bkg from "../assets/headerBKG.png";
import eye from "../assets/Eyes.png";
import gold__sol from "../assets/gold__sol.png";
import top__solpeepz from "../assets/newSolpeepz.svg";
import madfire from "../assets/madfire.png";
import NFT_for_life from "../assets/NFT_for_life.png";
import gif from "../assets/gif.gif";
import intro from "../assets/intro.mp4";
import which from "../assets/which.png";
import "./Header.css";

function Header() {
  return (
    <>
      {/* <div className='header'>
            <div>
            <img  className='bkg' src={bkg} alt=""/>  
            </div>
      
            <div className='coolest'>
                The coolest collectible<br></br>
                on the Solana blockchain
     
            </div> 

        </div>
        <div className="black"></div>
    
        <div className="eyes">
            <img className='eye__img' src={eye} alt=""/>

        </div> */}
      <div className="header">
        <div className="topbar">
          <div className="topbar__body">
            <div className="madfire">
              <img className="madfireImg" src={madfire} alt="" />
            </div>
            <div className="centerText">
              <img className="top__solpeepz" src={top__solpeepz} alt="" />
            </div>
            {/* <div className="top__discord">
            <a href="https://t.co/DKYXf6xZd2">
              <img className="top__discordImg" src={top__discord} alt="" />
              </a>
              <div className="invite1">
               DISCORD INVITE
              </div>
            </div> */}
            <a className="top__discord" href="https://twitter.com/SOlPeepzNFT">
              <div className="nav__disc__footer  nav__topbar">@SolPeepzNFT</div>
            </a>
          </div>
        </div>

        <div className="gold__solpeepz">
          <video className="gold__solpeepzImg" autoPlay muted loop>
            <source src={intro} type="video/mp4" />
          </video>
        </div>

        <div className="coolest">
          The coolest collectible on the Solana block
        </div>
        <div className="nft__for__life__gif">
          <div>
            <img className="nft_for_life" src={NFT_for_life} alt="" />
          </div>
          <div>
            <img src={gif} alt="" />
          </div>
          <div style={{ width: "100%" }}>
            <img className="which" src={which} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
