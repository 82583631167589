import React from "react";
import c1 from "../assets/s1.png";
import c2 from "../assets/s2.png";
import c3 from "../assets/s3.png";
import c4 from "../assets/s4.png";
import c5 from "../assets/s5.png";
import c6 from "../assets/s6.png";
import c7 from "../assets/s7.png";
import c8 from "../assets/s8.png";
import teaser from "../assets/teaser.mp4";
import c10 from "../assets/s10.png";
import c11 from "../assets/s11.png";
import c12 from "../assets/s12.png";
import c13 from "../assets/s13.png";
import c14 from "../assets/s14.png";
import c15 from "../assets/s15.png";
import c16 from "../assets/s16.png";
import c17 from "../assets/s17.png";
import c18 from "../assets/s18.png";
import c19 from "../assets/s19.png";
import c20 from "../assets/s20.png";
import c21 from "../assets/s21.png";
import extra from "../assets/extra.png";
import "./Slider.css";
function Slider() {
  const changeImg = (e) => {
    e.setAttribute("src", c1);
  };
  return (
    <div className="marquee">
      <div className="track">
        <div
          style={{
            width: "100%",
            marginTop: "5vh",
          }}
          className="slider__flex content"
        >
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c1)}
              onMouseOut={(e) => (e.currentTarget.src = c1)}
              className="slider__img"
              src={c1}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c2)}
              onMouseOut={(e) => (e.currentTarget.src = c2)}
              className="slider__img"
              src={c2}
              alt=""
            ></img>
          </div>

          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = teaser)}
              onMouseOut={(e) => (e.currentTarget.src = c3)}
              className="slider__img"
              src={c3}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c4)}
              onMouseOut={(e) => (e.currentTarget.src = c4)}
              className="slider__img"
              src={c4}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c5)}
              onMouseOut={(e) => (e.currentTarget.src = c5)}
              className="slider__img"
              src={c5}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c6)}
              onMouseOut={(e) => (e.currentTarget.src = c6)}
              className="slider__img"
              src={c6}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c7)}
              onMouseOut={(e) => (e.currentTarget.src = c7)}
              className="slider__img"
              src={c7}
              alt=""
            ></img>
          </div>
          <div>
            <img
              onMouseOver={(e) => (e.currentTarget.src = "./teaser.gif")}
              onMouseOut={(e) => (e.currentTarget.src = extra)}
              className="slider__img"
              src={extra}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c8)}
              onMouseOut={(e) => (e.currentTarget.src = c8)}
              className="slider__img"
              src={c8}
              alt=""
            ></img>
          </div>

          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c10)}
              onMouseOut={(e) => (e.currentTarget.src = c10)}
              className="slider__img"
              src={c10}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c11)}
              onMouseOut={(e) => (e.currentTarget.src = c11)}
              className="slider__img"
              src={c11}
              alt=""
            ></img>
          </div>

          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c12)}
              onMouseOut={(e) => (e.currentTarget.src = c12)}
              className="slider__img"
              src={c12}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c2)}
              onMouseOut={(e) => (e.currentTarget.src = c13)}
              className="slider__img"
              src={c13}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = "./teaser.gif")}
              onMouseOut={(e) => (e.currentTarget.src = c14)}
              className="slider__img"
              src={c14}
              alt=""
            ></img>
          </div>
          <div>
            <img
              onMouseOver={(e) => (e.currentTarget.src = "./teaser.gif")}
              onMouseOut={(e) => (e.currentTarget.src = extra)}
              className="slider__img"
              src={extra}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c4)}
              onMouseOut={(e) => (e.currentTarget.src = c15)}
              className="slider__img"
              src={c15}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c5)}
              onMouseOut={(e) => (e.currentTarget.src = c16)}
              className="slider__img"
              src={c16}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c7)}
              onMouseOut={(e) => (e.currentTarget.src = c18)}
              className="slider__img"
              src={c18}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c6)}
              onMouseOut={(e) => (e.currentTarget.src = c17)}
              className="slider__img"
              src={c17}
              alt=""
            ></img>
          </div>

          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c6)}
              onMouseOut={(e) => (e.currentTarget.src = c19)}
              className="slider__img"
              src={c19}
              alt=""
            ></img>
          </div>

          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c6)}
              onMouseOut={(e) => (e.currentTarget.src = c20)}
              className="slider__img"
              src={c20}
              alt=""
            ></img>
          </div>

          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c6)}
              onMouseOut={(e) => (e.currentTarget.src = c21)}
              className="slider__img"
              src={c21}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c1)}
              onMouseOut={(e) => (e.currentTarget.src = c1)}
              className="slider__img"
              src={c1}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c2)}
              onMouseOut={(e) => (e.currentTarget.src = c2)}
              className="slider__img"
              src={c2}
              alt=""
            ></img>
          </div>

          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = "./teaser.gif")}
              onMouseOut={(e) => (e.currentTarget.src = c3)}
              className="slider__img"
              src={c3}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c4)}
              onMouseOut={(e) => (e.currentTarget.src = c4)}
              className="slider__img"
              src={c4}
              alt=""
            ></img>
          </div>

          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c5)}
              onMouseOut={(e) => (e.currentTarget.src = c5)}
              className="slider__img"
              src={c5}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c6)}
              onMouseOut={(e) => (e.currentTarget.src = c6)}
              className="slider__img"
              src={c6}
              alt=""
            ></img>
          </div>
          <div>
            <img
              onMouseOver={(e) => (e.currentTarget.src = "./teaser.gif")}
              onMouseOut={(e) => (e.currentTarget.src = extra)}
              className="slider__img"
              src={extra}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c7)}
              onMouseOut={(e) => (e.currentTarget.src = c7)}
              className="slider__img"
              src={c7}
              alt=""
            ></img>
          </div>
          {/* <div>
            <img
              onMouseOver={(e) => (e.currentTarget.src = "./teaser.gif")}
              onMouseOut={(e) => (e.currentTarget.src = extra)}
              className="slider__img"
              src={c3}
              alt=""
            ></img>
          </div> */}
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c8)}
              onMouseOut={(e) => (e.currentTarget.src = c8)}
              className="slider__img"
              src={c8}
              alt=""
            ></img>
          </div>

          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c10)}
              onMouseOut={(e) => (e.currentTarget.src = c10)}
              className="slider__img"
              src={c10}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c11)}
              onMouseOut={(e) => (e.currentTarget.src = c11)}
              className="slider__img"
              src={c11}
              alt=""
            ></img>
          </div>

          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c12)}
              onMouseOut={(e) => (e.currentTarget.src = c12)}
              className="slider__img"
              src={c12}
              alt=""
            ></img>
          </div>
          <div>
            <img
              onMouseOver={(e) => (e.currentTarget.src = "./teaser.gif")}
              onMouseOut={(e) => (e.currentTarget.src = extra)}
              className="slider__img"
              src={extra}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c2)}
              onMouseOut={(e) => (e.currentTarget.src = c13)}
              className="slider__img"
              src={c13}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = "./teaser.gif")}
              onMouseOut={(e) => (e.currentTarget.src = c14)}
              className="slider__img"
              src={c14}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c4)}
              onMouseOut={(e) => (e.currentTarget.src = c15)}
              className="slider__img"
              src={c15}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c5)}
              onMouseOut={(e) => (e.currentTarget.src = c16)}
              className="slider__img"
              src={c16}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c7)}
              onMouseOut={(e) => (e.currentTarget.src = c18)}
              className="slider__img"
              src={c18}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c6)}
              onMouseOut={(e) => (e.currentTarget.src = c17)}
              className="slider__img"
              src={c17}
              alt=""
            ></img>
          </div>

          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c1)}
              onMouseOut={(e) => (e.currentTarget.src = c1)}
              className="slider__img"
              src={c1}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c2)}
              onMouseOut={(e) => (e.currentTarget.src = c2)}
              className="slider__img"
              src={c2}
              alt=""
            ></img>
          </div>

          <div>
            <img
              onMouseOver={(e) => (e.currentTarget.src = "./teaser.gif")}
              onMouseOut={(e) => (e.currentTarget.src = extra)}
              className="slider__img"
              src={extra}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c4)}
              onMouseOut={(e) => (e.currentTarget.src = c4)}
              className="slider__img"
              src={c4}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c5)}
              onMouseOut={(e) => (e.currentTarget.src = c5)}
              className="slider__img"
              src={c5}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c6)}
              onMouseOut={(e) => (e.currentTarget.src = c6)}
              className="slider__img"
              src={c6}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c7)}
              onMouseOut={(e) => (e.currentTarget.src = c7)}
              className="slider__img"
              src={c7}
              alt=""
            ></img>
          </div>
          {/* <div>
            <img
              onMouseOver={(e) => (e.currentTarget.src = "./teaser.gif")}
              onMouseOut={(e) => (e.currentTarget.src = extra)}
              className="slider__img"
              src={c3}
              alt=""
            ></img>
          </div> */}
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c8)}
              onMouseOut={(e) => (e.currentTarget.src = c8)}
              className="slider__img"
              src={c8}
              alt=""
            ></img>
          </div>

          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c10)}
              onMouseOut={(e) => (e.currentTarget.src = c10)}
              className="slider__img"
              src={c10}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c11)}
              onMouseOut={(e) => (e.currentTarget.src = c11)}
              className="slider__img"
              src={c11}
              alt=""
            ></img>
          </div>

          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c12)}
              onMouseOut={(e) => (e.currentTarget.src = c12)}
              className="slider__img"
              src={c12}
              alt=""
            ></img>
          </div>
          <div>
            <img
              onMouseOver={(e) => (e.currentTarget.src = "./teaser.gif")}
              onMouseOut={(e) => (e.currentTarget.src = extra)}
              className="slider__img"
              src={extra}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c2)}
              onMouseOut={(e) => (e.currentTarget.src = c13)}
              className="slider__img"
              src={c13}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = "./teaser.gif")}
              onMouseOut={(e) => (e.currentTarget.src = c14)}
              className="slider__img"
              src={c14}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c4)}
              onMouseOut={(e) => (e.currentTarget.src = c15)}
              className="slider__img"
              src={c15}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c5)}
              onMouseOut={(e) => (e.currentTarget.src = c16)}
              className="slider__img"
              src={c16}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c7)}
              onMouseOut={(e) => (e.currentTarget.src = c18)}
              className="slider__img"
              src={c18}
              alt=""
            ></img>
          </div>
          <div>
            <img
              // onMouseOver={(e) => (e.currentTarget.src = c6)}
              onMouseOut={(e) => (e.currentTarget.src = c17)}
              className="slider__img"
              src={c17}
              alt=""
            ></img>
          </div>

          <div>
            <img
              onMouseOver={(e) => (e.currentTarget.src = "./teaser.gif")}
              onMouseOut={(e) => (e.currentTarget.src = extra)}
              className="slider__img"
              src={extra}
              alt=""
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Slider;
