import React from "react";
import "./Footer.css";
import disc from "../assets/Discord logo.png";
import twitter from "../assets/twitter (3).png";
import sol from "../assets/solLogo.png";
import invite from "../assets/DIscord Invite.png";
function Footer() {
  return (
    <div className="footer">
      <div>
        <p className="copyright" style={{ color: "#989599" }}>
          @2022 SolPeepz. All rights reserved. Terms and conditions apply.
        </p>
      </div>
      <div className="icons">
        <div style={{ padding: "10px" }}>
          <a href="https://solana.com/">
            <img className=" solBottom" src={sol} alt="" />
          </a>
        </div>
        <div style={{ padding: "10px" }}>
          <a href="https://t.co/DKYXf6xZd2">
            {/* <img className='socialImg' src={disc} alt=""/> */}
          </a>
        </div>
        <div style={{ padding: "10px" }}>
          <a href="https://twitter.com/SolPeepzNFT">
            <img className="socialImg" src={twitter} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
