import { Box, Grid, Grow } from "@mui/material";
import React from "react";
import head from "../assets/head.png";
import disc from "../assets/Discord logo.png";
import twitter from "../assets/twitter (3).png";
import invite from "../assets/button_1.png";
import crew from "../assets/crew.png";
import sol from "../assets/Solana logo .png";
import "./Info.css";

function Info() {
  return (
    <div className="info">
      <div className="joinText">
        <div className="join">JOIN THE</div>
        {/* <div className="crew">CREW</div>
        <div className="join_us_mint">
          <div className="make">Join us & make a mint!</div>
          <div className="make2">Join us & make a mint!</div>
        </div> */}
        <div className="crew">
          <img className="crew__img" src={crew} alt="" />
        </div>
      </div>

      <div className="info__bottom">
        <a className="top__discord__footer" href="https://t.co/DKYXf6xZd2">
          {/* <div className="nav__disc__footer">DISCORD INVITE</div> */}
        </a>
      </div>
    </div>
  );
}

export default Info;
