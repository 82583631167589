import { Box, Grid } from "@mui/material";
import React from "react";
import "./Plan.css";
import monkeyL from "../assets/monkeyL.png";
import monkeyR from "../assets/monkeyR.png";
import bombzaway1 from "../assets/bombzaway1.png";

function Plan() {
  return (
    <div className="planBkg">
      <img className="monkeyL" src={monkeyL} alt="" />
      <img className="monkeyR" src={monkeyR} alt="" />
      <div>
        <Box
          className="plan__grid"
          sx={{ flexGrow: 1, backgroundColor: "black" }}
        >
          <Grid alignItems="" container>
            <Grid item xs={12} md={6} lg={6}>
              <img className="bombzaway1" src={bombzaway1} alt="" />
            </Grid>
            <Grid className="plan__container" item xs={12} md={4} lg={4}>
              <p className="planman">SO WHAT'S THE PLAN MAN?</p>
              <div className="plan__all">
                <p className="plans">
                  Unlike some NFT projects out there, we’re not going to waste
                  your time with a dodgy roadmap, hot air and empty promises.
                  But we do have a lot of short AND long term plans and we're
                  itching to share them.
                </p>
                <p className="plans">
                  We have a stack of ideas and concepts in the backlog, some of
                  which you’ll start to see with the exclusive 888 'OG Founder
                  Mint', designed as a 'badge of honor' and produced with an
                  exclusive 'founder' attribute for our OG backers.
                </p>
                <p className="plans">
                  SolPeepz recognises that great projects have great
                  communities, and whereas at this time we have taken the
                  decision to remain focused on our work and output, at the cost
                  of hosting a discord server, we do know we need strong and
                  active retweets, promoters and word spreaders to be succesful,
                  and thats where you come in...
                </p>
                <p className="plans">
                  We are looking to offer a fair and balanced community mint and
                  where possible reduce bots bulk buying, after all 'Sharing is
                  Caring' right! With this in mind, there will be two mint
                  phases. Phase 1 will release 888 exclusive NFTs, recognisable
                  by their gold SolPeepz labels and 'Founder Black' Backgrounds.
                  Following that, Phase two will introduce a set of 8000
                  colorful, Exclusive, 1 of a kind SolPeepz, peppered with a
                  number of exclusive rare items to enrich the collection and
                  add elements for future function.
                </p>
                <p className="plans">
                  We will have more detailed information and teasers as we get
                  closer to the mint date. SO, follow closely on twitter
                  @SolPeepzNFT, retweet and promote whereever possible and know
                  we are watching. A selection of unique retweeters and our most
                  active followers WILL be invited to the initial 'Founder
                  Mint'.
                </p>
                {/* <p className="plansRed">
                  Your SolPeepz journey starts with Discord, so please follow
                  the 'Discord Invite' links at the top and bottom of our site.
                  We will also be leading an active Twitter engagement campaign,
                  which can be be accessed from our Twitter Account{" "}
                  <span className="sleep">
                    <a href="https://twitter.com/SolPeepzNFT"> SolPeepzNFT</a>
                  </span>{" "}
                  or via the twitter link at the bottom of our site. Where
                  Tweeting, Retweeting, #Tagging and building our community will
                  be both recognised and rewarded. (More to come....).
                </p>
                <p className="plansRed">
                  Once invites have run out, follow our Twitter account, where
                  we will drop more invites.
                </p> */}
                <p className="plansRed2">
                  SolPeepz droppin REAL soon —
                  <span className="sleep">
                    <a
                      style={{ color: "white" }}
                      href="https://twitter.com/SolPeepzNFT"
                    >
                      don't sleep on this.
                    </a>
                  </span>
                </p>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}

export default Plan;
