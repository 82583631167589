import React from "react";
import "./GoldImage.css";
import { Box, Grid } from "@mui/material";
import gold from "../assets/gold.png";
import icon from "../assets/Icon.png";
import "./GoldImage.css";
import head from "../assets/head.png";
import bkg from "../assets/headerBKG.png";
import eye from "../assets/Eyes.png";
import Slider from "./Slider";
export default function SolpeepzCollection() {
  return (
    <div>
      <Box className="goldPart2" sx={{ flexGrow: 1, backgroundColor: "white" }}>
        <Grid className="solpeepz__container" alignItems="" container>
          <Grid className="firstPart" item xs={12} md={12} lg={6}>
            <Grid alignItems="center" container>
              <Grid item xs={12} md={12} lg={12}>
                <p className="collection">
                  The <div></div>
                  SolPeepz<div></div>Collection
                </p>
                <div className="bar1"></div>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            className="align__collection solpeepzText"
            item
            xs={10}
            md={12}
            lg={4}
          >
            <p>
              'Solpeepz' will be the next breakthrough project on the Solana
              blockchain. We think you’ll agree, there’s nothing like this
              around, and the demand for one of these NFTs is already ramping
              right up.
            </p>

            <p>
              There’s more to this than meets the eye. For a hint of what might
              be to come, we suggest you read&nbsp;
              <a
                style={{ color: "black", fontWeight: "700" }}
                href="https://solpeepz.substack.com/?r=1g1qe4&s=w&utm_campaign=pub&utm_medium=web"
              >
                'The Story of SolPeepz'.
              </a>
              &nbsp; Beyond that we can’t say too much, but we think early
              collectors are going to really enjoy the ride!
            </p>
            <p>
              To get in early on SolPeepz, you’ll need to follow us on Twitter,
              interact with us, furiously retweet and be a Solpeepz promoter
              legend.
            </p>
          </Grid>
        </Grid>
        <Grid className="" item xs={12} md={12} lg={12}>
          <Slider />
        </Grid>
      </Box>
    </div>
  );
}
