import "./App.css";
import Header from "./components/Section1/Header";
import Info from "./components/Section1/Info";
import GoldImage from "./components/Section2/GoldImage";
import Slider from "./components/Section2/Slider";
import Slider2 from "./components/Section2/Slider2";
import SolpeepzCollection from "./components/Section2/SolpeepzCollection";
import Footer from "./components/Section3/Footer";
import Plan from "./components/Section3/Plan";

function App() {
  return (
    <div className="App">
      <Header />

      {/* <Slider2 /> */}
      <SolpeepzCollection />
      <GoldImage />

      <div className="slider__component"></div>

      <Plan />
      <Info />
      <Footer />
    </div>
  );
}

export default App;
