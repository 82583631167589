import { Box, Grid } from "@mui/material";
import React from "react";
import gold from "../assets/gold.png";
import icon from "../assets/Icon.png";
import "./GoldImage.css";
import head from "../assets/head.png";
import bkg from "../assets/headerBKG.png";
import solana from "../assets/solanalogo.png";
function GoldImage() {
  return (
    <div>
      <Box className="goldPart" sx={{ flexGrow: 1, backgroundColor: "black" }}>
        <Grid alignItems="center" container>
          <Grid className="eyes__info" item xs={12} md={12} lg={12}>
            <Box className="head__and__text" sx={{ flexGrow: 1 }}>
              <Grid alignItems="center" container>
                <Grid className="leftText" item xs={12} md={4} lg={4}>
                  <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                      <div className="text"> 1 Crazy Collection</div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <div className="text"> 8888 Unique Solpeepz</div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <div className="text"> 888 Special Editions</div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <div className="text"> 88 Shiny Rare Editions</div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <div className="text"> 8 SolKing Editions</div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="head" item xs={12} md={4} lg={4}>
                  <img className="head__img" src={head} alt="" />
                </Grid>
                <Grid className="rightText" item xs={12} md={4} lg={4}>
                  <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                      <div className="text"> Multiple Traits</div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <div className="text"> Profile Picture Power</div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <div className="text"> Mystery Additions</div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <div className="text"> Whitelist Priority</div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <div className="text"> Community Rewards</div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <div style={{ width: "100%" }}>
            <img className="solana__logo" src={solana} alt="" />
          </div>

          <Grid
            className="attributes"
            container
            spacing={8}
            style={{ backgroundColor: "#1d1d1d88" }}
          >
            <Grid item xs={12} md={6} lg={3}>
              <Grid alignItems="center" container>
                <Grid className="upperText" item xs={12} md={12} lg={12}>
                  <div className="bar"></div>
                  Quality
                </Grid>
                <Grid className="belowText" item xs={12} md={12} lg={12}>
                  ...not quantity; We took our time to bring the SolPeepz
                  concept to life. Each SolPeep is crafted with love and
                  attitude ensuring a quality token.
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Grid alignItems="center" container>
                <Grid className="upperText" item xs={12} md={12} lg={12}>
                  <div className="bar"></div>
                  Style
                </Grid>
                <Grid className="belowText" item xs={12} md={12} lg={12}>
                  SolPeepz dress different; they don’t conform, and they got the
                  swagger to match. They ooze style. Your Solpeep will always
                  look the part.
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Grid alignItems="center" container>
                <Grid className="upperText" item xs={12} md={12} lg={12}>
                  <div className="bar"></div>
                  Aesthetic
                </Grid>
                <Grid className="belowText" item xs={12} md={12} lg={12}>
                  The look of the SolPeep is unique —a mix of styles, forming a
                  completely new aesthetic. Oh yes! They are all beautiful in
                  their own way.
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Grid alignItems="center" container>
                <Grid className="upperText" item xs={12} md={12} lg={12}>
                  <div className="bar"></div>
                  Authenticity
                </Grid>
                <Grid className="belowText" item xs={12} md={12} lg={12}>
                  No two SolPeepz are the same, each one unique. Created from
                  over 180 unique traits, a single SolPerson is 100% genuine Mad
                  Fire!
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <Grid alignItems="center" container>
                <Grid className="upperText" item xs={12} md={12} lg={12}>
                  <div className="bar"></div>
                  Future Proof
                </Grid>
                <Grid className="belowText" item xs={12} md={12} lg={12}>
                  NFTs are naturally future-proof, and with our plans, so is the
                  SolPeepz Project. Its future lies in community and that’s
                  where we will be.
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Grid alignItems="center" container>
                <Grid className="upperText" item xs={12} md={12} lg={12}>
                  <div className="bar"></div>
                  Roadmap
                </Grid>
                <Grid className="belowText" item xs={12} md={12} lg={12}>
                  Everyone loves a roadmap right?! Wrong. But we love it when a
                  good plan comes together. So buckle up and get with it!
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Grid alignItems="center" container>
                <Grid className="upperText" item xs={12} md={12} lg={12}>
                  <div className="bar"></div>
                  Community First
                </Grid>
                <Grid className="belowText" item xs={12} md={12} lg={12}>
                  We‘re all about the peepz. Early supporters will get rewards.
                  Holders will be treated as VIPS, and the community are going
                  to WIN!
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Grid alignItems="center" container>
                <Grid className="upperText" item xs={12} md={12} lg={12}>
                  <div className="bar"></div>
                  Team
                </Grid>
                <Grid className="belowText" item xs={12} md={12} lg={12}>
                  We're made up of artists and devs based in the United Kingdom.
                  We’re all bringing our skill-sets together to make something
                  special.
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default GoldImage;
